import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import StreamSignupForm from '../components/StreamSignupForm';

const StreamSignupPage = () => (
  <Layout>
    <SEO title="Stream Subscribe" />
    <div className="container">
      <div className="row">
        <div className="col-12 col-sm-6 lg-col-3 mx-auto">
          <h3 className="mb-5">Sign up to get notified whenever a live stream starts!</h3>
          <StreamSignupForm />
          <hr className="mt-5" />
          <p className="text-justify">
            Privacy Info:
            <br />
            horny.moe uses
            {' '}
            <a href="https://www.mailgun.com/">Mailgun</a>
            {' '}
            for managing our email lists. Your email is only saved to our mailing list in Mailgun,
            no other systems. You can unsubscribe by clicking the &apos;unsubscribe&apos; link in
            any of the emails you receive from us. When you unsubscribe we fully delete
            your email address from Mailgun. We don&apos;t store your email address on any of our
            own systems for any period of time.
          </p>
        </div>
      </div>
    </div>
  </Layout>
);

export default StreamSignupPage;
