import React from 'react';
import axios from 'axios';
import './index.css';

class StreamSignupForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      submitting: false,
      success: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) { this.setState({ value: event.target.value }); }

  handleSubmit = async (event) => {
    event.preventDefault();
    const { value } = this.state;
    if (!value) {
      return;
    }
    try {
      await this.setState({ submitting: true });
      await axios.post('https://api.horny.moe/subscribe/', {
        email: value,
      });
      await this.setState({ success: true, value: '' });
    } catch (err) {
      console.log(err);
    }
    await this.setState({ submitting: false });
  }

  render() {
    const { value, submitting, success } = this.state;
    if (success) {
      return (
        <div>
          <center>
            <h3>Thank You!</h3>
            <p>You&apos;ll receive an email the next time we go live!</p>
          </center>
        </div>
      );
    }
    return (
      <form
        style={{
          maxWidth: '400px',
          margin: '0 auto 0 auto',
        }}
        onSubmit={this.handleSubmit}
      >
        {' '}
        <label
          style={{
            width: '100%',
          }}
          htmlFor="emailField"
        >
          Email Address:
          <input
            disabled={submitting}
            style={{
              marginTop: '10px',
              width: '100%',
            }}
            id="emailField"
            type="text"
            value={value}
            onChange={this.handleChange}
          />
        </label>
        <input className="formSubmitButton" disabled={submitting} type="submit" value="Hook Me Up!" />
      </form>
    );
  }
}

export default StreamSignupForm;
